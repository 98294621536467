body {
  font-family: "Quicksand", sans-serif;
  background-color: #a397d8;
  color: #fff;
}

.App {
  overflow: hidden;
  height: 100vh;
  position: relative;
}

img {
  width: 100%;
}

a {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.video {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.logo {
  position: absolute;
  max-width: 200px;
}

.btn-group {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1050px;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 140px;
}

.btn-group-row {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.btn-wrapper {
  background-color: #b97643;
  border: 1px solid #1e160e;
  border-radius: 15px;
  padding: 5px;
  position: relative;
  cursor: pointer;
}

.btn-wrapper-icon {
  position: absolute;
  top: -40px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  background-color: #ffba4a;
  border: 1px solid #1e160e;
  border-radius: 15px;
  width: 280px;
  height: 50px;
  padding: 10px 15px;
  font-size: 30px;
  font-weight: 700;
  color: #1e160e;
  transition: all 0.3s linear;
}

.btn:hover {
  opacity: 0.5;
}

.btn-icon-sp img {
  width: 41px;
}

.btn-social {
  position: absolute;
  left: 50%;
  bottom: 70px;
  transform: translateX(-50%);
  display: flex;
  gap: 30px;
}

.footer {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  text-align: center;
  color: #1e160e;
  font-weight: 700;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #a397d8;
  width: 50%;
  padding: 100px 50px;
  text-align: center;
  border-radius: 10px;
}

.modal.hidden {
  display: none;
}

.modal h4 {
  font-size: 30px;
  font-weight: bold;
}

.modal-btn-group {
  margin-top: 50px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  gap: 50px;
}

.modal-btn-group span {
  display: inline-flex;
  background-color: #ffba4a;
  border: 1px solid #1e160e;
  padding: 15px 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s linear;
}

.modal-btn-group span:hover {
  /* background-color: #fff;
  color: #ffba4a; */
  opacity: 0.7;
}

@-webkit-keyframes buzz-out-on-hover {
  10% {
    -webkit-transform: translateY(3px) rotate(2deg);
    transform: translateY(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateY(-3px) rotate(-2deg);
    transform: translateY(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateY(3px) rotate(2deg);
    transform: translateY(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateY(-3px) rotate(-2deg);
    transform: translateY(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateY(2px) rotate(1deg);
    transform: translateY(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateY(-2px) rotate(-1deg);
    transform: translateY(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateY(2px) rotate(1deg);
    transform: translateY(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateY(-2px) rotate(-1deg);
    transform: translateY(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateY(1px) rotate(0);
    transform: translateY(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(-1px) rotate(0);
    transform: translateY(-1px) rotate(0);
  }
}
@keyframes buzz-out-on-hover {
  10% {
    -webkit-transform: translateY(3px) rotate(2deg);
    transform: translateY(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateY(-3px) rotate(-2deg);
    transform: translateY(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateY(3px) rotate(2deg);
    transform: translateY(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateY(-3px) rotate(-2deg);
    transform: translateY(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateY(2px) rotate(1deg);
    transform: translateY(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateY(-2px) rotate(-1deg);
    transform: translateY(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateY(2px) rotate(1deg);
    transform: translateY(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateY(-2px) rotate(-1deg);
    transform: translateY(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateY(1px) rotate(0);
    transform: translateY(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(-1px) rotate(0);
    transform: translateY(-1px) rotate(0);
  }
}

.buzz-out-on {
  -webkit-animation-name: buzz-out-on-hover;
  animation-name: buzz-out-on-hover;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@media screen and (max-width: 900px) {
  .btn-group {
    gap: 50px;
  }
  .btn-group-row {
    flex-direction: column;
    align-items: center;
  }

  .modal {
    width: 80%;
    padding: 50px 20px;
  }
}

@media screen and (max-width: 600px) {
  .modal {
    width: 85%;
    border: 1px solid #1e160e;
  }
  .modal h4 {
    font-size: 25px;
  }
  .modal-btn-group {
    gap: 20px;
    font-size: 18px;
  }
  .btn {
    font-size: 28px;
  }
}
